import React from "react"
import Layout from '../components/layout'
import { Link } from "gatsby"
import Logo from "../images/eidosstudio.svg"


export default function Index() {


  return (
 
    
      <Layout>

 
      
        <div className="ph0" >
              <div className="w-100 center bg-black cf">

                <div className="w-100 min-vh-100 bg-bottom-right" style={{ backgroundImage: `url(${Logo})`, backgroundSize: '40%' }}>
                    <div className="min-vh-100 flex items-center justify-center">

                      <div className="ph2 mw-600 animate-03 fadeInSubtle">

                          <div>
                            <div className="f1 fw9 gray-300 ">Eidos</div> 
                            <div className="f2 fw1 gray-100 ">Studio</div>
                          </div>

                          <div className="w-100 f5 ls-xsm gray-50 fw3 pv2">
                              Eidos design studio specialises in modern bespoke publishing, graphic design, ideation and branding, full stack engineering for web applications. 
                              <p></p>
                              The word <b>eidos</b> means to discover the essence, form or essential nature of an object, idea, or thought.
                              <p></p>
                              The core of our service offering is to understand the essence of your communication needs. 
                              <p></p>
                              With our people having more than 27 years of experience in the communication, publishing and media space, Eidos Studio is more than qualified to bring form to your vision.
                          </div>

                          
                          <Link className="link ls-xsm br-1 bg-gray-50 black-900  ph2 pv05 f6 pointer tc center dib hover-bg-white-50 mb1" to={`/menu-page`} >
                            Okay – show me the portfolio
                          </Link>

                          <Link className="db mb1"  to={`/custom-publish`} >
                            <span className="link ls-xsm br-1 bg-gray-50 black-900  ph2 pv05 f6 pointer tc center dib hover-bg-white-50">
                            A case study in Modern Publishing
                            </span>
                          </Link>


                          <div className="w-100 f5 ls-xsm gray-50 fw3 pt2 pb4">
                              <p className="f5 ttu fw5 ls-sm gray-50 bb b-white-50 pb05" >Contact the studio</p>
                              <p> <a className="gray-50 hover-white-50 link" href="mailto:rod@eidos.co.za?subject=Thank you for making contact with Eidos Studio">Send an email to Eidos Studio</a></p>
                          </div>
                          
                

                      </div>

                   

                    </div>
                </div>
            </div>
            </div>
           
       
         
 
       
      </Layout>
  
    
  )
}
